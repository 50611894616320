<template>
    <div style="padding: 20px">
        <div class="flex justify-between">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="顾客姓名/手机号/关键字">
                    <el-input v-model="searchForm.search" placeholder="顾客姓名/手机号/关键字"></el-input>
                </el-form-item>
                <el-form-item label="选择年月">
                    <el-date-picker
                            v-model="changeTime"
                            type="month"
                            format="yyyy 年 MM 月"
                            value-format="yyyy-MM"
                            placeholder="选择年月"
                            @change="getTime"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
                    <el-button type="primary"  @click="exportMessage()">导出营业报表</el-button>
                </el-form-item>
            </el-form>
            <!-- 操作按钮 -->
            <el-form :inline="true">
                <el-form-item>
                    <el-button icon="el-icon-plus" @click="toEdit()">添加记录</el-button>
                    <!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="顾客姓名"></el-table-column>
            <el-table-column label="日期">
                <template slot-scope="scope">
                    {{scope.row.year}}-{{scope.row.month}}
                </template>
            </el-table-column>
            <el-table-column prop="service" label="服务项目"></el-table-column>
            <el-table-column prop="number" label="服务次数"></el-table-column>
            <el-table-column prop="consume_count" label="实耗合计"></el-table-column>
            <el-table-column prop="cash_achievement" label="现金业绩"></el-table-column>
            <el-table-column prop="cash_income" label="现金实收"></el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button @click="toEdit(scope.row)" size="mini">编辑</el-button>
                    <el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
                        <el-button slot="reference" size="mini">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div class="text-center margin-top">
            <el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="pageSizes" :page-size="searchForm.page_size"
                           layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loading: false, // 表格加载状态
                changeTime:'',
                tableData: [], // 数据
                pageSizes: [],
                searchForm: {
                    search: '', // 否 string 用于搜索的关键字
                    year:'',
                    month:'',
                    page: 1, // 否 int 页码
                    page_size: 10, // 否 int 页脚
                },
                total: 0,
            };
        },
        mounted() {
            // 查询数据
            this.search()
        },
        methods: {
            getTime(){
                this.searchForm.year = this.changeTime ? this.changeTime.substring(0,4) : ''
                this.searchForm.month = this.changeTime ? this.changeTime.substring(this.changeTime.length - 2,this.changeTime.length) : ''
                console.log(this.searchForm.year)
                console.log(this.searchForm.month)
            },
            search() {
                this.loading = true;
                this.$api.table.businessList(this.searchForm).then(res => {
                    console.log(res)
                    this.tableData = res.data.data;
                    this.total = res.data.total;
                    this.pageSizes = res.data.per_page.toString().split()
                    this.searchForm.page_size = res.data.per_page
                    this.loading = false;
                })
            },
            pageSizeChange(size) {
                this.searchForm.page_size = size;
                this.search()
            },
            pageChange(page) {
                this.searchForm.page = page;
                this.search()
            },
            toEdit(item) {
                if (item) {
                    this.$router.push(`/tables/business/edit/${item.id}`)
                } else {
                    this.$router.push(`/tables/business/edit`)
                }
            },
            del(item) {
                const loading = this.$loading()
                this.$api.table.delBusiness({
                    id: item.id
                }).then(res => {
                    loading.close()
                    if (res.code == 200) {
                        this.$message.success(`操作成功`);
                        this.search()
                    } else {
                        this.$message.error(res.msg);
                    }
                }).catch(err => {
                    loading.close()
                    this.$alert(err);
                });
            },
            exportMessage(){
                this.$api.table.businessExport({
                    search:this.searchForm.search,
                    year:this.searchForm.year,
                    month:this.searchForm.month,
                }).then(res => {
                    console.log(res)
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: "application/vnd.ms-excel"
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl)
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '营业报表.xls');
                    a.click();
                })
            }
        },
    };
</script>

<style scoped lang="scss">
    .cover {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 10px;
    }
</style>
